.start-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-image: radial-gradient(at top left, rgba(109, 213, 237, 0.2) 30%, rgba(33, 147, 176, 0.2) 70%, rgba(144, 164, 174, 0.2) 90%, rgba(176, 190, 197, 0.2) 100%);
    color: #ffffff;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    text-align: center;
    overflow: hidden;
    position: relative;
    animation: fadeInScreen 1.5s ease-out forwards;
    opacity: 0; /* Start with the screen hidden */
}

.start-screen h1 {
    font-size: 3.5rem;
    margin-bottom: 60px;
    color: #101010;
    text-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
    letter-spacing: 1.2px;
    position: relative;
    transform: translateY(-20px);
    opacity: 0;
    animation: slideInDown 1s ease-out 0.5s forwards, textGlow 4s ease-in-out 1s infinite alternate;

}

.links-container {
    display: flex;
    justify-content: center;
    gap: 30px;
    padding: 0 10px;
    position: relative;
    transform: translateY(20px);
    opacity: 0;
    animation: slideInUp 0.75s ease-out 0.5s forwards;
}
@keyframes fadeInScreen {
    to { opacity: 1; }
}

@keyframes slideInDown {
    from { transform: translateY(-20px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
}

@keyframes slideInUp {
    from { transform: translateY(20px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
}

@keyframes textGlow {
    from { text-shadow: 3px 3px 20px rgba(255, 255, 255, 0.5); }
    to { text-shadow: 3px 3px 10px rgba(0, 0, 0, 0.5); }
}

.start-link {
    padding: 15px 40px;
    background: #ffffff;
    color: #007bff; /* Standard blue for text */
    text-decoration: none;
    border: 2px solid #007bff; /* Transparent border for gradient */
    border-radius: 25px; /* Softly rounded corners */
    font-size: 1rem;
    font-weight: 500; /* Medium font weight */
    letter-spacing: 0.7px; /* Subtle letter spacing */
    cursor: pointer;
    transition: all 0.3s ease;
    display: inline-block;
    margin: 10px;
    position: relative; /* For pseudo-elements */
    background-clip: padding-box; /* Prevents background from bleeding into the box-shadow */
}

.start-link::before {
    content: '';
    position: absolute;
    border-radius: inherit;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    background-color: rgba(0, 0, 0, 0.1); /* Slightly dark overlay */
    transition: opacity 0.3s ease;
    opacity: 0; /* Default state with no overlay */
}

.start-link:hover::before, .start-link:focus::before {
    opacity: 1;
}

.start-link:hover, .start-link:focus {
    transform: translateY(-1px); /* Slight lift on hover */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12); /* Deeper shadow on hover */
}

.start-link:focus {
    outline: none; /* Remove outline for a clean look */
}

.fade-out {
    animation: fadeOutScreen 0.5s ease-out forwards;
}

@keyframes fadeOutScreen {
    from {opacity: 1; }
    to { opacity: 0; }
}