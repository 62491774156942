.fade-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: white;
    z-index: 20;
    animation: fadeOut 1.5s ease-out forwards;
    opacity: 1;
}

@keyframes fadeOut {
    from { opacity: 1; }
    to { opacity: 0; }
}


.fade-overlay.no-interaction {
    pointer-events: none;
}
