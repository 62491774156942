:root {
    --primary-blue: #2563eb;
    --primary-purple: #7c3aed;
    --primary-green: #059669;
    --primary-orange: #ea580c;
    --background-color: #f8fafc;
    --card-background: #ffffff;
    --text-secondary: #4b5563;
    --text-muted: #6b7280;
    --border-color: #e5e7eb;
    --input-background: #ffffff;
    --input-border: #e5e7eb;
    --input-focus: #2563eb;
    --text-primary: #111827;
    --button-primary: #2563eb;
    --button-hover: #1d4ed8;
    --button-active: #1e40af;
    --error-color: #dc2626;
    --shadow-sm: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    --shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
    --shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
}

body {
    background-color: var(--background-color);
    color: var(--text-primary);
}

.dashboard-container {
    padding: 1.25rem;
    max-width: 1400px;
    margin: 0 auto;
    animation: fadeIn 0.3s ease-out;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

/* Header Section */
.header-section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1.5rem;
    background: var(--card-background);
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

@media (min-width: 768px) {
    .header-section {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
}

.dashboard-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--text-primary);
}

/* Search Bar */
.search-container {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    width: 100%;
    max-width: 400px;
    margin: 0 1rem;
}

.search-input {
    width: 100%;
    padding: 0.5rem 0.75rem 0.5rem 2.25rem;
    border: 1px solid var(--border-color);
    border-radius: 0.375rem;
    font-size: 0.875rem;
    transition: all 0.2s ease;
}

.search-input:focus {
    outline: none;
    border-color: var(--primary-blue);
    box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.1);
}

.search-icon {
    position: absolute;
    left: 0.75rem;
    top: 50%;
    transform: translateY(-50%);
    color: var(--text-muted);
    width: 0.875rem;
    height: 0.875rem;
}

/* Stats Grid */
.stats-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    gap: 1rem;
    margin-bottom: 1.5rem;
}

.stats-card {
    background: var(--card-background);
    padding: 1rem;
    border-radius: 0.5rem;
    border: 1px solid var(--border-color);
    transition: transform 0.2s ease;
}

.stats-card:hover {
    transform: translateY(-2px);
}

.stats-card-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.stats-label {
    font-size: 0.875rem;
    color: var(--text-muted);
    margin-bottom: 0.25rem;
}

.stats-value {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--text-primary);
}

/* Icons */
.icon-base {
    width: 1.25rem;
    height: 1.25rem;
}

.icon-blue { color: var(--primary-blue); }
.icon-purple { color: var(--primary-purple); }
.icon-green { color: var(--primary-green); }
.icon-orange { color: var(--primary-orange); }

/* Filters */
.filters-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
    margin-bottom: 1.5rem;
    padding: 1rem;
    background: var(--card-background);
    border-radius: 0.5rem;
    border: 1px solid var(--border-color);
}

.filter-select {
    padding: 0.375rem 2rem 0.375rem 0.75rem;
    font-size: 0.875rem;
    border: 1px solid var(--border-color);
    border-radius: 0.375rem;
    background-color: var(--card-background);
    cursor: pointer;
}

.export-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.375rem 1rem;
    font-size: 0.875rem;
    color: white;
    background-color: var(--primary-blue);
    border: none;
    border-radius: 0.375rem;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.export-button:hover {
    background-color: #1d4ed8;
}

/* Submissions List */
.submissions-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1rem;
}

.submission-card {
    background: var(--card-background);
    border: 1px solid var(--border-color);
    border-radius: 0.5rem;
    padding: 1rem;
    cursor: pointer;
    transition: all 0.2s ease;
}

.submission-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.submission-header {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.75rem;
    height: 3.5rem; /* Set a fixed height to ensure consistent size */
}

.submission-title {
    font-size: 1rem;
    font-weight: 600;
    color: var(--text-primary);
}

.submission-details {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;
    margin-bottom: 0.75rem;
}

.detail-item {
    display: flex;
    align-items: center;
    gap: 0.375rem;
    font-size: 0.75rem;
    color: var(--text-secondary);
}

.detail-icon {
    width: 0.875rem;
    height: 0.875rem;
    color: var(--text-muted);
}

.submission-meta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.75rem;
    padding-top: 0.75rem;
    border-top: 1px solid var(--border-color);
}

.badge {
    padding: 0.25rem 0.5rem;
    font-size: 0.75rem;
    font-weight: 500;
    color: var(--primary-blue);
    background-color: #dbeafe;
    border-radius: 1rem;
}

.representatives-count {
    font-size: 0.75rem;
    max-width: 12rem;
    color: var(--text-muted);
}

/* Modal Styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease;
    z-index: 50;
}

.modal-overlay.active {
    opacity: 1;
    visibility: visible;
}

.modal-content {
    background: var(--card-background);
    width: 90%;
    max-width: 500px;
    max-height: 90vh;
    border-radius: 0.5rem;
    padding: 1.5rem;
    position: relative;
    overflow-y: auto;
}

.modal-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: none;
    border: none;
    cursor: pointer;
    color: var(--text-muted);
}

.modal-title {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
    padding-right: 2rem;
}

.modal-section {
    margin-bottom: 1.5rem;
}

.modal-section h3 {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0.75rem;
    color: var(--text-primary);
}

.modal-section p {
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
    color: var(--text-secondary);
}

/* Loading State */
.loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 400px;
}

.loading-spinner {
    animation: spin 1s linear infinite;
    color: var(--primary-blue);
}

@keyframes spin {
    to { transform: rotate(360deg); }
}

/* Error State */
.error-message {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 1rem;
    background-color: #fee2e2;
    border-radius: 0.5rem;
    color: #991b1b;
}
.login-container {
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     padding: 2rem;
     animation: fadeIn 0.5s ease-out;
 }

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.login-title {
    font-size: 2rem;
    font-weight: 700;
    color: var(--text-primary);
    margin-bottom: 2rem;
    position: relative;
    text-align: center;
    animation: slideDown 0.5s ease-out;
}

@keyframes slideDown {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.login-form {
    width: 100%;
    max-width: 400px;
    background: var(--input-background);
    padding: 2rem;
    border-radius: 0.75rem;
    box-shadow: var(--shadow-lg);
    animation: formAppear 0.5s ease-out 0.2s both;
    border: 1px solid var(--input-border);
    display: flex;
    flex-direction: column;
    align-items: center; /* This centers the form elements */
}

.form-group {
    margin-bottom: 1.5rem;
    width: 90%; /* Reduces width to create padding on sides */
}

.form-label {
    display: block;
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--text-secondary);
    margin-bottom: 0.5rem;
}

.btn {
    width: 90%; /* Matches form-group width */
    padding: 0.75rem 1.5rem;
    font-size: 0.875rem;
    font-weight: 500;
    color: white;
    background-color: var(--primary-blue);
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: all 0.2s ease;
    position: relative;
    overflow: hidden;
}

@keyframes formAppear {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.form-input {
    border-width: 1.5px;
    border-color: #cbd5e1;
    width: 100%;
    border-radius: 10px;
    padding: 5px;
}
.form-input:hover {
    border-width: 1.5px;
    border-color: black;
    width: 100%;
    border-radius: 10px;
    padding: 5px;
}
.password-container {
    position: relative;
    width: 100%;
}

.eye-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.form-input:focus {
    border-color: var(--primary-blue);
    box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.1);
}

.btn {
    width: 100%;
    margin-top: 30px;
    padding: 0.75rem 1.5rem;
    font-size: 0.875rem;
    font-weight: 500;
    color: white;
    background-color: var(--primary-blue);
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: all 0.2s ease;
    position: relative;
    overflow: hidden;
}

@keyframes ripple {
    0% {
        transform: scale(0, 0);
        opacity: 0.5;
    }
    100% {
        transform: scale(100, 100);
        opacity: 0;
    }
}

/* Error state */
.form-input.error {
    border-color: var(--error-color);
}

.error-message {
    color: var(--error-color);
    font-size: 0.75rem;
    margin-top: 0.5rem;
}

/* Responsive adjustments */
@media (max-width: 640px) {
    .login-container {
        padding: 1rem;
    }

    .login-title {
        font-size: 1.5rem;
    }

    .login-form {
        padding: 1.5rem;
    }
}

/* Loading state */
.btn.loading {
    position: relative;
    color: transparent;
}

.btn.loading::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 1.25rem;
    height: 1.25rem;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-top-color: white;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to { transform: translate(-50%, -50%) rotate(360deg); }
}