:root {
    --navbar-height: 6rem;
    --navbar-height-scrolled: 4rem;
    --primary-gradient: linear-gradient(135deg, #da46e5 0%, #4F46E5 30%, #7C3AED 100%);
    --shadow-subtle: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    --shadow-elevated: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.nav-wrapper {
    height: var(--navbar-height);
    position: sticky;
    top: 0;
    z-index: 999;
    width: 100%;
}
/* Base navbar styles */
.nav-wrapper .navbar {
    /* whatever your default is */
    background: var(--primary-gradient); /* purple? */
}

/* If theme = "company" */
.nav-wrapper.navbar--company .navbar {
    background: linear-gradient(135deg, #da46e5 0%, #16a34a 30%, #22c55e 100%);
}

/* If theme = "club" */
.nav-wrapper.navbar--club .navbar {
    background: linear-gradient(135deg, #da46e5 0%, #f97316 30%, #fb923c 100%);
}

/* If theme = "lab" */
.nav-wrapper.navbar--lab .navbar {
    background: linear-gradient(135deg, #da46e5 0%, #6d28d9 30%, #7c3aed 100%);
}

.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--primary-gradient);
    position: fixed;
    box-shadow: var(--shadow-subtle);
    backdrop-filter: blur(8px);
    padding: 0 2rem;
}

.navbar-links {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.navbar-links_logo img {
    height: 3rem;
    width: auto;
    transition: height 0.3s ease;
}

.navbar-links_container {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    gap: 1rem;
}

.navbar-links_container p,
.navbar-menu_container p {
    margin: 0;
}

.navbar-links_container a,
.navbar-menu_container a {
    color: white;
    text-decoration: none;
    font-weight: 500;
    font-size: 1.125rem;
    padding: 0.75rem 1.25rem;
    border-radius: 12px;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background: rgba(255, 255, 255, 0.1);
    transition: background 0.2s ease;
}

.navbar-links_container a:hover,
.navbar-menu_container a:hover {
    background: rgba(255, 255, 255, 0.2);
}

.navbar-menu {
    margin-left: 1rem;
    display: none;
    position: relative;
}

.navbar-menu_container {
    display: flex;
    flex-direction: column;
    background: var(--primary-gradient);
    padding: 1rem;
    position: absolute;
    top: calc(100% + 0.5rem);
    right: 0;
    min-width: 220px;
    border-radius: 16px;
    box-shadow: var(--shadow-elevated);
    gap: 0.5rem;
}

@media screen and (max-width: 1050px) {
    .navbar-links_container {
        display: none;
    }

    .navbar-menu {
        display: flex;
    }
}

@media screen and (max-width: 700px) {
    .navbar {
        padding: 0 1.5rem;
    }
}

@media screen and (max-width: 550px) {
    .navbar {
        padding: 0 1rem;
    }

    .navbar-links_logo img {
        height: 2rem;
    }
}